import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import React, { Component } from 'react';
import './richedit.scss';
import { Editor } from 'slate-react';

const DEFAULT_NODE = 'paragraph';

function Toolbar(props) {
  return <div className="toolbar">{props.children}</div>;
}

function BoldMark(props) {
  return <b>{props.children}</b>;
}

function MarkHotkey(options) {
  const { type, key } = options;

  return {
    onKeyDown(event, editor, next) {
      if (!event.ctrlKey || event.key !== key) return next();
      event.preventDefault();
      editor.toggleMark(type);
    }
  };
}

function AddSubList() {
  return {
    onKeyDown(event, editor, next) {
      if (event.key !== 'Tab') return next();
      const isList = editor.value.blocks.some(node => node.type === 'list-item');
      if (isList) {
        event.preventDefault();
        if (event.shiftKey) {
          editor.setBlocks('list-item').unwrapBlock('bulleted-list');
        } else {
          editor.setBlocks('list-item').wrapBlock('bulleted-list');
        }
      } else {
        return next();
      }
    }
  };
}

const plugins = [
  MarkHotkey({ key: 'b', type: 'bold' }),
  MarkHotkey({ key: 'i', type: 'italic' }),
  MarkHotkey({ key: 'u', type: 'underline' }),
  MarkHotkey({ key: 'r', type: 'right' }),
  AddSubList()
];

function wrapLink(editor, href) {
  editor.wrapInline({
    type: 'link',
    data: { href }
  });

  editor.moveToEnd();
}

function unwrapLink(editor) {
  editor.unwrapInline('link');
}

function insertHorizontalLine(editor, target) {
  if (target) {
    editor.select(target);
  }
  editor.insertBlock({ type: 'horizontal-line', data: {} });
}

function insertImage(editor, src, target) {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: 'image',
    data: { src }
  });
}

class RichEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.initialValue };
    this.handleChange = this.handleChange.bind(this);
    this.onPhotoClick = this.onPhotoClick.bind(this);
    props.onPhotoClickCallback(this.onPhotoClick);
    this.changeCounter = 0;
  }

  schema = {
    blocks: {
      'horizontal-line': {
        isVoid: true
      },
      image: {
        isVoid: true
      }
    }
  };

  handleChange({ value }) {
    // if (value.document !== this.state.value.document) {
    //   const content = JSON.stringify(value.toJSON());
    //   localStorage.setItem('content', content);
    // }
    this.setState({ value });
    if (this.props.onChange) this.props.onChange(value);
  }

  // LINKs

  hasLinks = () => {
    const { value } = this.state;
    return value.inlines.some(inline => inline.type === 'link');
  };

  // MARKs

  hasMark = type => {
    const { value } = this.state;
    return value.activeMarks.some(mark => mark.type === type);
  };

  markRender = ({ type, jsx }) => {
    return (
      <button
        type="button"
        key={'mark' + type}
        className={'font-style' + (this.hasMark(type) ? ' font-style-selected' : '')}
        onMouseDown={event => this.onClickMark(event, type)}
      >
        {jsx}
      </button>
    );
  };

  onClickMark = (event, type) => {
    this.editor.toggleMark(type);
  };

  renderMark = (props, editor, next) => {
    switch (props.mark.type) {
    case 'bold':
      return <BoldMark {...props} />;
    case 'code':
      return <code>{props.children}</code>;
    case 'italic':
      return <em>{props.children}</em>;
    case 'strikethrough':
      return <del>{props.children}</del>;
    case 'underline':
      return <u>{props.children}</u>;
    case 'right':
      return <span className="right">{props.children}</span>;
    default:
      return next();
    }
  };

  // BLOCKs

  hasBlock = type => {
    const { value } = this.state;
    return value.blocks.some(node => node.type === type);
  };

  blockRender = ({ type, jsx }) => {
    let isActive = this.hasBlock(type);
    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const { value } = this.state;
      if (value.blocks.size > 0) {
        const parent = value.document.getParent(value.blocks.first().key);
        isActive = this.hasBlock('list-item') && parent && parent.type === type;
      }
    }
    return (
      <button
        type="button"
        key={'block' + type}
        className={'font-style' + (isActive ? ' font-style-selected' : '')}
        onMouseDown={event => this.onClickBlock(event, type)}
      >
        {jsx}
      </button>
    );
  };

  onClickBlock = (event, type) => {
    event.preventDefault();
    if (type === 'link') {
      return this.onClickLink();
    }

    if (type === 'horizontal-line') {
      return this.onClickHorizontalLine();
    }

    const { editor } = this;
    const { value } = editor;
    const { document } = value;
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      const isList = this.hasBlock('list-item');
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type);
      });

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        editor.unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list').wrapBlock(type);
      } else {
        editor.setBlocks('list-item').wrapBlock(type);
      }
    }
  };

  onClickHorizontalLine = () => {
    this.editor.command(insertHorizontalLine);
  };

  onPhotoClick = url => {
    this.editor.command(insertImage, url);
  };

  renderNode = (props, editor, next) => {
    const { attributes, children, node, isFocused } = props;

    switch (node.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link': {
      const { data } = node;
      const href = data.get('href');
      return (
        <a href={href} {...attributes}>
          {children}
        </a>
      );
    }
    case 'horizontal-line':
      return <hr className={isFocused ? 'hr-focused' : ''} {...attributes} />;
    case 'prices':
      return (
        <div className="prices" {...attributes}>
          {children}
        </div>
      );
    case 'image':
      return (
        <img
          src={node.data.get('src')}
          className={isFocused ? 'image-inline image-inline-focused' : 'image-inline'}
          alt=""
          {...attributes}
        />
      );

    default:
      return next();
    }
  };

  onClickLink = () => {
    const { editor } = this;
    const { value } = editor;
    const hasLinks = this.hasLinks();

    if (hasLinks) {
      editor.command(unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt('Enter the URL of the link:');

      if (href === null) {
        return;
      }

      editor.command(wrapLink, href);
    } else {
      const href = window.prompt('Enter the URL of the link:');

      if (href === null) {
        return;
      }

      const text = window.prompt('Enter the text for the link:');

      if (text === null) {
        return;
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapLink, href);
    }
  };

  onClickImage = src => {
    this.editor.command(insertImage, src);
  };

  onPaste = (...event) => {};

  regEditor(editor) {
    this.editor = editor;
  }

  render() {
    const marks = [
      { type: 'bold', jsx: <b>B</b> },
      { type: 'italic', jsx: <i>I</i> },
      { type: 'underline', jsx: <u>U</u> },
      { type: 'right', jsx: <div> {'->|'} </div> }
    ];

    const blocks = [
      { type: 'heading-one', jsx: '1' },
      { type: 'heading-two', jsx: '2' },
      { type: 'numbered-list', jsx: '#' },
      { type: 'bulleted-list', jsx: '.' },
      { type: 'link', jsx: '<a>' },
      { type: 'horizontal-line', jsx: '-' },
      { type: 'prices', jsx: 'P' }
    ];
    return (
      <div className="richedit">
        <Toolbar>
          {marks.map(mark => this.markRender(mark))}
          {blocks.map(block => this.blockRender(block))}
        </Toolbar>
        <Editor
          className="editor"
          ref={editor => (this.editor = editor)}
          value={this.state.value}
          onChange={this.handleChange}
          renderNode={this.renderNode}
          renderMark={this.renderMark}
          onPast={this.onPaste}
          plugins={plugins}
          schema={this.schema}
        />
      </div>
    );
  }
}

export default RichEdit;
