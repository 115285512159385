import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/functions';

var config = {
  apiKey: 'AIzaSyCzZJAosWwBGXtW5eM38HJIxoSrl5pg7yY',
  authDomain: 'happyontheroad-3c56b.firebaseapp.com',
  databaseURL: 'https://happyontheroad-3c56b.firebaseio.com',
  projectId: 'happyontheroad-3c56b',
  clientId: '71561800288-srga3gn2oap55s3kh4bveipati65d7ve.apps.googleusercontent.com'
};
firebase.initializeApp(config);

export var database = firebase.database();
export var functions = firebase.functions();

export default firebase;
