import English from './english';
import Slovak from './slovak';

const languages = {
  en: English,
  sk: Slovak
};

const getText = (language, id) => {
  return languages[language] ? languages[language][id] : English[id];
};

export default getText;
