import firebase from './firebase';
import 'firebase/auth';

var provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/photoslibrary');

export const userLogIn = () => {
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function() {
      return firebase.auth().signInWithPopup(provider);
    })
    .catch(function(error) {});
  // return new Promise(function(resolve, reject) {
  //     resolve({ credential: 'yes'});
  // });
};

export const getCredentials = ({ idToken, accessToken }) => {
  firebase.auth.GoogleAuthProvider.credential(idToken, accessToken);
};
