import React from 'react';
import Html from 'slate-html-serializer';

const BLOCK_TAGS = {
  p: 'paragraph',
  blockquote: 'quote',
  h1: 'heading-one',
  h2: 'heading-two',
  ol: 'numbered-list',
  ul: 'bulleted-list',
  li: 'list-item',
  hr: 'horizontal-line',
  div: 'prices',
  img: 'image'
};

const MARK_TAGS = { i: 'italic', b: 'bold', u: 'underline', span: 'right' };

const INLINE_TAGS = { a: 'link', hr: 'horizontal-line' };

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'block',
          type: type,
          data: {
            className: el.getAttribute('class'),
            src: el.getAttribute('src')
          },
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
        case 'heading-one':
          return <h1 className={obj.data.get('className')}>{children}</h1>;
        case 'heading-two':
          return <h2 className={obj.data.get('className')}>{children}</h2>;
        case 'numbered-list':
          return <ol className={obj.data.get('className')}>{children}</ol>;
        case 'bulleted-list':
          return <ul className={obj.data.get('className')}>{children}</ul>;
        case 'paragraph':
          return <p className={obj.data.get('className')}>{children}</p>;
        case 'quote':
          return <blockquote className={obj.data.get('className')}>{children}</blockquote>;
        case 'list-item':
          return <li className={obj.data.get('className')}>{children}</li>;
        case 'horizontal-line':
          return <hr className={obj.data.get('className')} />;
        case 'prices':
          return <div className={obj.data.get('className')}>{children}</div>;
        case 'image':
          return <img src={obj.data.get('src')} alt="" className="image-inline" />;
        default:
          return null;
        }
      }
    }
  },
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'mark',
          type: type,
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
        case 'bold':
          return <b>{children}</b>;
        case 'italic':
          return <i>{children}</i>;
        case 'underline':
          return <u>{children}</u>;
        case 'right':
          return <span className="right">{children}</span>;
        default:
          return null;
        }
      }
    }
  },
  {
    deserialize(el, next) {
      const type = INLINE_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'inline',
          type: type,
          data: { href: el.href },
          nodes: next(el.childNodes)
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'inline') {
        switch (obj.type) {
        case 'link':
          return (
            <a
              className={obj.data.get('className')}
              href={obj.data.get('href')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          );
        default:
          return null;
        }
      }
    }
  }
];

const html = new Html({ rules });

export default html;
