import './footer.scss';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <a href="mailto:happyontheroad.eu@gmail.com" target="_blank" rel="noopener noreferrer">
          <img src="/img/icon-mail.png" alt="mail" />
        </a>
        <a
          href="https://www.facebook.com/Happy-on-the-road-1823230757804402/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/icon-facebook.png" alt="facebook" />
        </a>
        <a href="https://www.instagram.com/happyontheroad.eu" target="_blank" rel="noopener noreferrer">
          <img src="/img/icon-instagram.png" alt="instagram" />
        </a>
        <div>Copyright</div>
        <div>Cifko 2018</div>
        <Link to="/login" className="pi">
          π
        </Link>
      </footer>
    );
  }
}

export default Footer;
