import './fetcherror.scss';
import React from 'react';

const FetchError = ({ message, onRetry }) => (
  <div className="fetch-error">
    <p>Could not fetch. {message}</p>
    <button onClick={onRetry}>Retry</button>
  </div>
);

export default FetchError;
