import { combineReducers } from 'redux';
import reducerRegistry from '../../lib/reducerRegistry';
import v4 from 'uuid';

const addSection = (state = {}, action) => {
  switch (action.type) {
  case 'ADD_SECTION':
    return { id: v4(), type: action.style };
  default:
    return state;
  }
};

const sections = (state = [], action) => {
  switch (action.type) {
  case 'ADD_SECTION':
    return [...state, addSection(state, action)];
  default:
    return state;
  }
};

const addPost = combineReducers({
  sections
});

reducerRegistry.register('addPost', addPost);

export const getSections = state => state.addPost.sections;
