import './upsertsection.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'actions';
import { withRouter, Prompt } from 'react-router-dom';
import getText from 'languages';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getFeed, getIsFeedFetching, getErrorMessageFeed } from 'reducers';
import Spinner from 'components/Spinner/Spinner';

class UpsertSection extends Component {
  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchData();
    }
  }

  fetchData() {
    const { feed, fetchFeed } = this.props;
    if (feed === undefined || feed.length === 0) fetchFeed();
  }

  handleModal(yes) {}

  handleYes = handler => yes => {
    if (yes) handler();
  };

  handleReset = reset => {
    this.props.showModal(getText(this.props.match.params.language, '/form/reset'), true, this.handleYes(reset));
  };

  render() {
    let {
      feed,
      feedIsFetching,
      upsertSection,
      match: {
        params: { language, path, type }
      }
    } = this.props;
    if (feedIsFetching !== false || (feed === undefined || !feed.length)) {
      return <Spinner />;
    }

    if (type === 'update') {
      const name = path.split('/').slice(-1)[0];
      const parent = path
        .split('/')
        .slice(0, -1)
        .join('/');
      const actualFeed = feed.find(
        item => item.name === name && (item.path === parent || (item.path === undefined && parent === ''))
      );
      this.initialValues = {
        name: actualFeed.name,
        path: actualFeed.path,
        title: { [language]: '', ...actualFeed.title }
      };
    } else {
      this.initialValues = {
        name: '',
        title: { [language]: '' },
        path
      };
    }
    return (
      <div className="container">
        <Formik
          initialValues={this.initialValues}
          enableReinitialize
          // onSubmit={(values, actions) => {
          onSubmit={values => {
            const { name, title, path } = values;
            upsertSection(name, path, title);
            this.props.history.goBack();
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            title: Yup.object({
              [language]: Yup.string().required('Required')
            })
          })}
          // render={({errors, touched, isSubmitting, handleReset, values }) => (
          render={({ isSubmitting, handleReset }) => (
            <Form>
              <Prompt message="Are you sure you want to go to " when={!isSubmitting} />
              <div className="row">
                <div className="col-2">{getText(language, '/upsert_section/name')}</div>
                <div className="col-2">
                  <Field
                    type="text"
                    name="name"
                    placeholder={getText(language, '/upsert_section/name')}
                    disabled={isSubmitting}
                  />
                  <ErrorMessage name="name">{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-2">{getText(language, '/upsert_section/title')}</div>
                <div className="col-2">
                  <Field
                    type="text"
                    name={`title.${language}`}
                    placeholder={getText(language, '/upsert_section/title')}
                    disabled={isSubmitting}
                  />
                  <ErrorMessage name={`title.${language}`}>
                    {msg => <div className="error-message">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <button type="submit" disabled={isSubmitting}>
                    {getText(language, '/form/button/submit')}
                  </button>
                </div>
                <div className="col-2">
                  <button type="button" onClick={() => this.handleReset(handleReset)} disabled={isSubmitting}>
                    {getText(language, '/form/button/reset')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feed: getFeed(state),
    feedIsFetching: getIsFeedFetching(state),
    feedErrorMessage: getErrorMessageFeed(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(UpsertSection)
);
