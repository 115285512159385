import './modal.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getModalVisible, getModalText, getModalYesNo, getModalHandler } from 'reducers';
import * as actions from 'actions';
import { withRouter } from 'react-router-dom';
import getText from 'languages';

class Modal extends Component {
  click = val => {
    const { hideModal, handler } = this.props;
    hideModal();
    if (handler) handler(val);
  };

  clickYes = () => this.click(true);
  clickNo = () => this.click(false);

  render() {
    const {
      visible,
      text,
      yesno,
      match: {
        params: { language }
      }
    } = this.props;
    if (!visible) return null;
    return (
      <div className="modal-wrapper">
        <div className="modal">
          <button className="close" onClick={this.clickNo.bind(this)}>
            &times;
          </button>
          <div className="text">{text}</div>
          {yesno ? (
            <div>
              <button className="modal-button" onClick={this.clickYes.bind(this)}>
                {getText(language, '/modal/yes')}
              </button>
              <button className="modal-button" onClick={this.clickNo.bind(this)}>
                {getText(language, '/modal/no')}
              </button>
            </div>
          ) : (
            <button className="modal-button" onClick={this.clickNo.bind(this)}>
              {getText(language, '/modal/ok')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    visible: getModalVisible(state),
    text: getModalText(state),
    yesno: getModalYesNo(state),
    handler: getModalHandler(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(Modal)
);
