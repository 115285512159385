const Slovak = {
  '/albums/insert': 'Vložiť',
  '/form/button/reset': 'Zresetovať',
  '/form/button/submit': 'Uložiť',
  '/form/reset': 'Chceš načítať východzie dáta?',
  '/header/about': 'O nás',
  '/header/blog': 'Blog',
  '/header/destinations': 'Destinácie',
  '/header/our_location': 'Naša poloha',
  '/header/photo_video': 'Foto a Video',
  '/header/search': 'Hľadaj',
  '/modal/no': 'Nie',
  '/modal/ok': 'Ok',
  '/modal/yes': 'Áno',
  '/post/insert_post': 'Pridať príspevok',
  '/post/insert_category': 'Pridať kategóriu',
  '/upsert_album/name': 'Url názov',
  '/upsert_album/photo_title': 'Titulok',
  '/upsert_post/name': 'URL Názov',
  '/upsert_post/text': 'Text',
  '/upsert_post/title': 'Názov',
  '/upsert_section/id': 'Id (debug)',
  '/upsert_section/imgSrc': 'Link na obrázok',
  '/upsert_section/name': 'URL Názov',
  '/upsert_section/title': 'Názov sekcie',
  '/loadmore': 'Načítať viac',
  '/about/us': 'Fotka nás na kopci "Poon hill" v Nepále. V pozadí je Dhaulagiri, siedma najvyššia hora sveta.',
  '/our_location/desc': 'Niektoré texty sú napísané dopredu v prípade, že vieme že nebudeme mať prístup k internetu ;-)'
};

export default Slovak;
