import React, { Component } from 'react';
import './style.scss';
import { Route, Switch, Redirect } from 'react-router';
import Browser from 'scenes/Browser/Browser';
import PhotoVideo from 'scenes/PhotoVideo/PhotoVideo';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Post from 'scenes/Post/Post';
import Login from 'admin/components/Login.js';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import UpsertPost from 'scenes/UpsertPost/UpsertPost';
import UpsertSection from 'scenes/UpsertSection/UpsertSection';
import Modal from 'components/Modal/Modal';
import UpsertAlbum from 'scenes/UpsertAlbum/UpsertAlbum';
import Feed from 'scenes/Feed/Feed';
import About from 'scenes/About/About';
import OurLocation from 'scenes/OurLocation/OurLocation';

// <Switch>
//   <Route path="/:language(sk|en)/destinations/:path*/:type(insert|update)" />
//   <Route path="/:language(sk|en)/destinations/:path*" component={JumbotronBrowser} />
//   <Route exact path="/:language/blog" component={JumbotronFeed} />
// </Switch>

class App extends Component {
  render() {
    return (
      <div className="App background">
        <Route path="/:language(sk|en)" component={Header} />
        <Switch>
          <PrivateRoute path="/:language(sk|en)/destinations/:path*/:type(insert|update)" component={UpsertSection} />
          <PrivateRoute path="/:language(sk|en)/blog/:path*/:type(insert|update)" component={UpsertPost} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/:language(sk|en)/our-location" component={OurLocation} />
          <Route exact path="/:language(sk|en)/blog" component={Feed} />
          <PrivateRoute path="/:language(sk|en)/photo_video/:type(insert)" component={UpsertAlbum} />
          <PrivateRoute path="/:language(sk|en)/photo_video/:album/:type(update)" component={UpsertAlbum} />
          <Route path="/:language(sk|en)/photo_video/:album?" component={PhotoVideo} />
          <Route path="/:language(sk|en)/blog/:path*" component={Post} />
          <Route path="/:language(sk|en)/destinations/:path*" component={Browser} />
          <Route path="/:language(sk|en)/about" component={About} />
          <Redirect from="/" to="/sk/about" />
        </Switch>
        <Footer />
        <Route path="/:language(sk|en)" component={Modal} />
      </div>
    );
  }
}

export default App;
