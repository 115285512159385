import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getIsAuthenticated, getFeed, getIsFeedFetching, getErrorMessageFeed } from 'reducers';
import { connect } from 'react-redux';
import * as actions from 'actions';
import { randItem, sortByTitle, sortBySecondTitle } from 'lib/basic';
import getText from 'languages';
import FeedList from 'components/FeedList/FeedList';
import FetchError from 'components/FetchError/FetchError';
import Jumbotron from 'components/Jumbotron/Jumbotron';
import Spinner from 'components/Spinner/Spinner';

class Browser extends Component {
  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchData();
    }
  }

  fetchData() {
    const { feed, fetchFeed } = this.props;
    if (feed === undefined || feed.length === 0) fetchFeed();
  }

  getRandImg(item) {
    if (item.cover) return item.cover;
    const selected = randItem(
      Object.keys(this.props.feed)
        .map(name => ({ name, ...this.props.feed[name] }))
        .filter(
          feed =>
            feed.cover !== undefined &&
            feed.path &&
            feed.path.startsWith(`${item.path ? item.path + '/' : ''}${item.name}`)
        )
    );
    return selected && selected.cover;
  }

  getLinkTo(feed) {
    const {
      match: { url }
    } = this.props;
    if (feed.link && (feed.link['post-sk'] || feed.link['post-en']))
      return `${url}/${feed.name}`.replace('destinations', 'blog');
    return `${url}/${feed.name}`;
  }

  convertCategoryToTile(feed) {
    const {
      match: {
        params: { language }
      }
    } = this.props;
    return {
      id: feed.name,
      title: feed.title[language] || `TEMP - ${feed.name}`,
      cover: this.getRandImg(feed),
      link: this.getLinkTo(feed)
    };
  }

  prepareData() {
    const {
      feed,
      authenticated,
      match: {
        params: { path, language }
      }
    } = this.props;
    const sortFunc = path ? sortByTitle : sortBySecondTitle;
    return Object.keys(feed)
      .filter(name => feed[name].path === (path ? path : '') && (feed[name].title[language] || authenticated))
      .map(name => this.convertCategoryToTile({ name, ...feed[name] }))
      .sort(sortFunc);
  }

  prepareJumboData() {
    const {
      feed,
      match: {
        params: { path, language }
      }
    } = this.props;
    return Object.keys(feed)
      .filter(
        name =>
          feed[name].path &&
          (!path || feed[name].path.startsWith(path)) &&
          feed[name].title[language] &&
          feed[name].cover
      )
      .map(name => ({
        id: name,
        // title: post.data.title[language],
        cover: feed[name].cover,
        link: ''
      }));
  }

  prepareAdmin() {
    const {
      authenticated,
      match: {
        params: { language, path }
      }
    } = this.props;
    if (!authenticated) return [];
    return [
      {
        id: 'addPost',
        title: getText(language, '/post/insert_post'),
        cover: '/img/add_file.png',
        link: `/${language}/blog/${path ? path + '/' : ''}insert`,
        admin: true
      },
      {
        id: 'addCategory',
        title: getText(language, '/post/insert_category'),
        cover: '/img/add_folder.png',
        link: this.props.match.url + '/insert',
        admin: true
      }
    ];
  }

  render() {
    const { feed, errorMessage } = this.props;
    if (errorMessage) {
      return <FetchError message={errorMessage} onRetry={() => this.fetchData()} />;
    }

    if (feed === undefined || feed.length === 0) {
      return <Spinner />;
    }

    return (
      <div>
        <Jumbotron items={this.prepareJumboData()} />
        <FeedList items={[...this.prepareData(), ...this.prepareAdmin()]} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feed: getFeed(state),
    feedIsFetching: getIsFeedFetching(state),
    feedErrorMessage: getErrorMessageFeed(state),
    authenticated: getIsAuthenticated(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(Browser)
);
