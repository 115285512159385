import './upsertalbum.scss';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik';
import {
  getPhotos,
  getIsFetchingPhotos,
  getErrorMessagePhotos,
  getFeed,
  getIsFeedFetching,
  getErrorMessageFeed
} from '../../reducers';
import { getUser } from 'reducers';
import { withRouter, Prompt } from 'react-router-dom';
import * as actions from 'actions';
import * as Yup from 'yup';
import FetchError from 'components/FetchError/FetchError';
import getText from 'languages';
import Spinner from 'components/Spinner/Spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { findRegexGroups } from 'lib/basic';

class Photo extends Component {
  render() {
    const { placeholder, url, isCover, isSubmitting, name, language, onPhotoClick } = this.props;
    return (
      <div className="col-sm-6 col-md-4 col-xl-3 justify-content-center">
        <div style={this.state} className={`tile_edit tile_edit_border ${isCover ? 'tile_edit_cover' : ''}`}>
          <img
            src={url.replace('/s1600/', '/s240/')}
            alt=""
            className="tile_edit__img"
            onClick={() => onPhotoClick(url)}
          />
          <Field
            className="container-fluid"
            type="text"
            name={name}
            disabled={isSubmitting}
            placeholder={placeholder || getText(language, '/upsert_album/photo_title')}
          />
        </div>
      </div>
    );
  }
}

class PhotoList extends Component {
  render() {
    const { photos, cover, isSubmitting, language, onPhotoClick, onAddClick } = this.props;
    return (
      <div className="row">
        <FieldArray
          name="photos"
          render={() => (
            <Fragment>
              {photos.map((photo, index) => {
                return (
                  <Photo
                    language={language}
                    key={index}
                    url={photo.url}
                    name={`photos.${index}.title.${language}`}
                    placeholder={photo.title[language === 'en' ? 'sk' : 'en']}
                    isSubmitting={isSubmitting}
                    onPhotoClick={onPhotoClick}
                    isCover={cover === photo.url}
                  />
                );
              })}
            </Fragment>
          )}
        />
        <Photo
          language={language}
          key="add"
          url="/img/add_photo.png"
          name={getText(language, '/albums/insert')}
          isSubmitting={true}
          onPhotoClick={onAddClick}
          placeholder=""
        />
      </div>
    );
  }
}

class UpsertAlbum extends Component {
  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      fetchPhotos,
      match: {
        params: { album }
      }
    } = this.props;
    if (album) fetchPhotos(album);
  }

  handleModal(yes) {}

  handleYes = handler => yes => {
    if (yes) handler();
  };

  handleReset = reset => {
    this.props.showModal(getText(this.props.match.params.language, '/form/reset'), true, this.handleYes(reset));
  };

  onPhotoClick = setFieldValue => url => {
    setFieldValue('cover', url);
  };

  onAddClick = () => {
    const {
      feed,
      photos,
      match: {
        params: { language, album }
      }
    } = this.props;
    const blogpostBody = prompt('blogpost body');
    const blogspotPhotos = findRegexGroups(/src="([^"]*)/gm, blogpostBody);
    console.log(blogspotPhotos);
    this.initialValues = {
      name: album,
      cover: feed[album].cover,
      date: new Date(feed[album].date),
      photos: {
        ...photos.map(photo => ({ ...photo, title: { [language]: '', ...photo.title } })),
        ...blogspotPhotos.map(photo => ({
          url: photo,
          title: { [language]: '' }
        }))
      },
      title: feed[album].title
    };
  };

  render() {
    const {
      feed,
      feedIsFetching,
      feedErrorMessage,
      photos,
      photosIsFetching,
      photosErrorMessage,
      upsertAlbum,
      match: {
        params: { language, type, album }
      }
    } = this.props;
    console.log(feed);
    if (type === 'update') {
      if (!feed || !photos || photos.length === 0 || feedIsFetching || photosIsFetching) {
        return <Spinner />;
      }
      if (feedErrorMessage) {
        return <FetchError message={feedErrorMessage} onRetry={() => this.fetchData()} />;
      }
      if (photosErrorMessage) {
        return <FetchError message={photosErrorMessage} onRetry={() => this.fetchData()} />;
      }
      const blogpostBody = prompt('blogpost body');
      const blogspotPhotos = findRegexGroups(/src="([^"]*)/gm, blogpostBody);
      console.log(blogspotPhotos);
      this.initialValues = {
        name: album,
        cover: feed[album].cover,
        date: new Date(feed[album].date),
        photos: [
          ...photos.map(photo => ({ ...photo, title: { [language]: '', ...photo.title } })),
          ...blogspotPhotos.map(photo => ({
            url: photo,
            title: { [language]: '' }
          }))
        ],
        title: feed[album].title
      };
    } else {
      const blogpostBody = prompt('blogpost body');
      const blogspotPhotos = findRegexGroups(/src="([^"]*)/gm, blogpostBody);
      this.initialValues = {
        name: '',
        cover: '',
        date: undefined,
        photos: blogspotPhotos.map(photo => ({
          url: photo,
          title: { [language]: '' }
        })),
        title: { [language]: '' }
      };
    }
    return (
      <div className="add-album">
        <div>
          <h2>Upsert Album</h2>
        </div>
        <Formik
          initialValues={this.initialValues}
          enableReinitialize
          // onSubmit={(values, actions) => {
          onSubmit={values => {
            const { name, title, date, cover, photos } = values;
            upsertAlbum(language, name, title, date, cover, photos).then(() => this.props.history.goBack());
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            title: Yup.string().required('Required'),
            date: Yup.string().required('Required')
          })}
          render={({ isSubmitting, handleReset, setFieldValue, values }) => (
            <Form>
              <Prompt message="Are you sure you want to go to " when={!isSubmitting} />
              <DatePicker
                placeholder="Date"
                selected={values.date}
                dateFormat="MMMM d, yyyy"
                onChange={dateString => setFieldValue('date', dateString)}
              />
              <div className="row">
                <div className="col-2">
                  <Field
                    type="text"
                    name="name"
                    className="container-fluid"
                    disabled={isSubmitting}
                    placeholder={getText(language, '/upsert_album/name')}
                  />
                  <ErrorMessage name="name">{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                </div>
                <div className="col-2">
                  <Field
                    type="text"
                    name={`title.${language}`}
                    className="container-fluid"
                    disabled={isSubmitting}
                    placeholder={getText(language, '/upsert_post/title')}
                  />
                  <ErrorMessage name="title">{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                </div>
                <div className="col-2">
                  <button type="submit" className="container-fluid" disabled={isSubmitting}>
                    {getText(language, '/form/button/submit')}
                  </button>
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="container-fluid"
                    onClick={() => this.handleReset(handleReset)}
                    disabled={isSubmitting}
                  >
                    {getText(language, '/form/button/reset')}
                  </button>
                </div>
              </div>
              <PhotoList
                language={language}
                photos={values.photos}
                isSubmitting={isSubmitting}
                onPhotoClick={this.onPhotoClick(setFieldValue).bind(this)}
                onAddClick={this.onAddClick.bind(this)}
                cover={values.cover}
              />
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feed: getFeed(state),
    feedIsFetching: getIsFeedFetching(state),
    feedErrorMessage: getErrorMessageFeed(state),
    photos: getPhotos(state),
    photosIsFetching: getIsFetchingPhotos(state),
    photosErrorMessage: getErrorMessagePhotos(state),
    user: getUser(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(UpsertAlbum)
);
