import './spinner.scss';
import React from 'react';

const Spinner = ({ className }) => (
  <div className={className}>
    Loading... <br />
    <img src="/img/ajax-loader.gif" alt="Loading" />
  </div>
);

export default Spinner;
