import './style.scss';
import React, { Component } from 'react';
import { getIsAuthenticated } from 'reducers/index';
import { connect } from 'react-redux';
import { resizeImage } from 'lib/image';
import { withRouter, Link } from 'react-router-dom';

class Item extends Component {
  render() {
    const { authenticated, admin, title, cover, link } = this.props;
    return (
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="post">
          <Link to={link}>
            <img src={resizeImage(cover, 300, 390)} alt={title} />
            <div className="cover" />
            <div className="title">{title}</div>
            <div className="reset" />
          </Link>
          {authenticated && !admin ? (
            <Link to={`${link}/update`}>
              <div className="edit" />
            </Link>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: getIsAuthenticated(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Item)
);
