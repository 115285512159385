import React, { Component } from 'react';
import './upsertpost.scss';
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {
  getFeed,
  getIsFeedFetching,
  getErrorMessageFeed,
  getPost,
  getIsPostFetching,
  getErrorMessagePost,
  getPhotos,
  getIsFetchingPhotos,
  getErrorMessagePhotos
} from 'reducers';
import { getUser } from 'reducers';
import { withRouter, Prompt } from 'react-router-dom';
import * as actions from 'actions';
import * as adminActions from 'admin/actions';
import * as Yup from 'yup';
import getText from 'languages';
import RichEdit from './components/RichEdit/RichEdit';
import Spinner from 'components/Spinner/Spinner';
import htmlSerializer from './components/RichEdit/htmlSerializer';
import { Textfit } from 'react-textfit';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Photo extends Component {
  render() {
    const { url, title, onPhotoClick } = this.props;
    return (
      <div className="list-item" onClick={() => onPhotoClick(url)}>
        <div>
          <img src={url} alt={title} className="tile__img" />
        </div>
        <div className="tile_title">
          <Textfit mode="single" forceSingleModeWidth={false}>
            {title}
          </Textfit>
        </div>
      </div>
    );
  }
}

class PhotoList extends Component {
  render() {
    const { photos, language, onPhotoClick } = this.props;
    return (
      <div className="list">
        {photos.map(photo => (
          <Photo
            key={photo.url}
            url={photo.url}
            title={photo.title[language] || photo.title.en || photo.name}
            onPhotoClick={onPhotoClick}
          />
        ))}
      </div>
    );
  }
}

class Album extends Component {
  render() {
    const { name, cover, title, onAlbumClick } = this.props;
    return (
      <div className="list-item" onClick={() => onAlbumClick(name)}>
        <div>
          <img src={cover} alt={title} className="tile__img" />
        </div>
        <div className="tile_title">
          <Textfit mode="single" forceSingleModeWidth={false}>
            {title}
          </Textfit>
        </div>
      </div>
    );
  }
}

class AlbumList extends Component {
  render() {
    const { albums, language, onAlbumClick } = this.props;
    return (
      <div className="list">
        {albums.map(album => (
          <Album
            key={album.name}
            name={album.name}
            cover={album.cover}
            title={album.title[language] || album.title.en || album.name}
            onAlbumClick={onAlbumClick}
          />
        ))}
      </div>
    );
  }
}

class AddPost extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { type, path }
      }
    } = this.props;
    if (type === 'update') {
      const name = path.split('/').slice(-1)[0];
      this.state = { name };
    } else {
      this.state = { name: '' };
    }
  }

  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params !== match.params) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      feed,
      fetchFeed,
      match: {
        params: { language, path }
      },
      fetchPost,
      fetchPhotos
    } = this.props;
    fetchPost(language, path);
    if (feed === undefined || feed.length === 0) fetchFeed();
    if (this.state.name) fetchPhotos(this.state.name);
  }

  handleModal(yes) {}

  handleYes = handler => yes => {
    if (yes) handler();
  };

  handleReset = reset => {
    this.props.showModal(getText(this.props.match.params.language, '/form/reset'), true, this.handleYes(reset));
  };

  render() {
    const {
      feed,
      photos,
      postIsFetching,
      post,
      upsertPost,
      match: {
        params: { language, type, path }
      },
      fetchPhotos
    } = this.props;
    if (feed === undefined || feed.length === 0 || postIsFetching) {
      return <Spinner />;
    }
    var name = '';
    if (type === 'update') {
      name = path.split('/').slice(-1)[0];
    } else if (this.state.name) name = this.state.name;
    this.initialValues = {
      date: undefined,
      name: '',
      title: '',
      path,
      text: htmlSerializer.deserialize('')
    };
    if (name) {
      this.initialValues = {
        date: new Date(feed[name].date),
        name,
        title: feed[name].title[language] || '',
        path: feed[name].path || path,
        text: htmlSerializer.deserialize((post && post.text) || '')
      };
    }
    return (
      <div className="add-post">
        <div>
          <h2>{type} Post</h2>
        </div>
        <Formik
          initialValues={this.initialValues}
          enableReinitialize
          // onSubmit={(values, actions) => {
          onSubmit={values => {
            const { name, title, text, path, date } = values;
            upsertPost(language, title, htmlSerializer.serialize(text), path, name, date);
            this.props.history.goBack();
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Required')
          })}
          // render={({errors, touched, isSubmitting, handleReset, values }) => (
          render={({ isSubmitting, handleReset, values, setFieldValue }) => {
            return (
              <Form>
                <Prompt message="Are you sure you want to go to " when={!isSubmitting} />
                <DatePicker
                  disabled={isSubmitting}
                  placeholder="Date"
                  selected={values.date}
                  dateFormat="MMMM d, yyyy"
                  onChange={dateString => setFieldValue('date', dateString)}
                />
                <div className="row">
                  <div className="col-2">
                    <Field
                      type="text"
                      name="name"
                      className="container-fluid"
                      disabled={isSubmitting || this.state.name !== ''}
                      placeholder={getText(language, '/upsert_post/name')}
                    />
                    <ErrorMessage name="title">{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                  </div>
                  <div className="col-2">
                    <Field
                      type="text"
                      name="title"
                      className="container-fluid"
                      disabled={isSubmitting}
                      placeholder={getText(language, '/upsert_post/title')}
                    />
                    <ErrorMessage name="title">{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                  </div>
                  <div className="col-2">
                    <button type="submit" className="container-fluid" disabled={isSubmitting}>
                      {getText(language, '/form/button/submit')}
                    </button>
                  </div>
                  <div className="col-2">
                    <button
                      type="button"
                      className="container-fluid"
                      onClick={() => this.handleReset(handleReset)}
                      disabled={isSubmitting}
                    >
                      {getText(language, '/form/button/reset')}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <RichEdit
                      disabled={isSubmitting}
                      initialValue={values.text}
                      onChange={value => setFieldValue('text', value)}
                      onPhotoClickCallback={callback => (this.onPhotoClickCallback = callback)}
                    />
                  </div>
                </div>
                <PhotoList photos={photos} language={language} onPhotoClick={this.onPhotoClickCallback} />
                {
                  <AlbumList
                    albums={Object.keys(feed)
                      .filter(name => feed[name].link && feed[name].link.album)
                      .map(name => ({ name, ...feed[name] }))}
                    language={language}
                    onAlbumClick={name => {
                      fetchPhotos(name);
                      this.setState({ name });
                    }}
                  />
                }
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, params) => {
  const { language } = params.match.params;
  return {
    // Feed
    feed: getFeed(state),
    feedIsFetching: getIsFeedFetching(state),
    feedErrorMessage: getErrorMessageFeed(state),
    // Post
    post: getPost(state, language),
    postIsFetching: getIsPostFetching(state, language),
    postErrorMessage: getErrorMessagePost(state, language),
    // Photos
    photos: getPhotos(state),
    photosIsFetching: getIsFetchingPhotos(state),
    photosErrorMessage: getErrorMessagePhotos(state),
    // User
    user: getUser(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { ...actions, ...adminActions }
  )(AddPost)
);
