import './style.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getIsAuthenticated } from 'reducers';
import { connect } from 'react-redux';
import * as actions from 'actions';
import getText from 'languages';

class About extends Component {
  renderMail = () => (
    <div>
      <a href="mailto:happyontheroad.eu@gmail.com" target="_blank" rel="noopener noreferrer">
        happyontheroad.eu@gmail.com
      </a>
    </div>
  );
  renderInstagram = () => (
    <div>
      <a href="https://www.instagram.com/happyontheroad.eu" target="_blank" rel="noopener noreferrer">
        happyontheroad.eu
      </a>
    </div>
  );
  renderFacebook = () => (
    <div>
      <a href="https://www.facebook.com/Happy-on-the-road-1823230757804402/" target="_blank" rel="noopener noreferrer">
        Happy on the road
      </a>
    </div>
  );

  renderSK() {
    return (
      <>
        <p>Ahojte. </p>
        <p>
          Voláme sa Martin a Júlia a pochádzame zo Slovenska (Bratislavy a Nitry). Láska k cestovaniu nás spojila v roku
          2015 a neskôr sme vytvorili blog, ktorý pomáha na cestách nám, keď už si všetko nepamätáme, ale hlavne má
          pomôcť vám, keď sa neviete rozhodnúť, kam by ste chceli vyraziť.
        </p>
        <p>
          Našou najobľúbenejšou destináciou je Ázia, do ktorej sa stále radi vraciame. V lete 2018 nás napadol šialený
          nápad a to precestovať spolu počas jedného roka krajiny JV Ázie, Austráliu a Oceániu.
        </p>
        <p>
          Spočiatku sme netušili, koľko roboty za tým bude, no s výsledkom sme spokojní a vy nás môžete od januára 2019
          sledovať v sekcii naša poloha. Budete mať vždy najnovšie info o tom, kde sa práve nachádzame a čo všetko sme
          videli.
        </p>
        <p>
          Budeme radi, keď nám napíšete s vašimi radami, nápadmi, alebo otázkami, pretože aj vďaka vám je tento blog
          ešte lepší.
        </p>
        <br />
        <div className="contact">Kontakt: {this.renderMail()}</div>
        <div className="instagram">Instagram: {this.renderInstagram()}</div>
        <div className="facebook">Facebook: {this.renderFacebook()}</div>
      </>
    );
  }

  renderEN() {
    return (
      <>
        <p>Hi!</p>
        <p>
          We are Martin and Julia from Slovakia. Love for travelling connected us in 2015 and later we created a blog,
          which helps us on the road, when we don&apos;t remember everything, but mainly it should help YOU when you
          don&apos;t know to decide, where you would like to go.
        </p>
        <p>Our favourite destination is Asia, where we like coming back.</p>
        <p>
          During summer in 2018 we had a crazy idea to travel one year to the countries of SE Asia, Australia and
          Oceania.
        </p>
        <p>
          In the beginning we didn&apos;t know how much work it is to plan everything, but we are really satisfied with
          the results. Since january 2019 you can follow us in section our location. You will have a newest information
          about where we stay and what we saw.
        </p>
        <p>
          We will be really happy, if you send us your advices, ideas or questions, because YOU can help to our blog to
          be much better.
        </p>
        <br />
        <div className="contact">Contact: {this.renderMail()}</div>
        <div className="instagram">Instagram: {this.renderInstagram()}</div>
        <div className="facebook">Facebook: {this.renderFacebook()}</div>
      </>
    );
  }

  render() {
    const {
      match: {
        params: { language }
      }
    } = this.props;
    return (
      <div className="about">
        <img
          alt={getText(language, '/about/us')}
          src="https://1.bp.blogspot.com/--ORTAlYqJ98/XAehWJYXLnI/AAAAAAAAvGI/LMTVHd-sCRgqeDUZyWZT2_363RV1JtNzgCLcBGAs/s1600/IMG-025.jpg"
        />
        <div className="container">{language === 'sk' ? this.renderSK() : this.renderEN()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: getIsAuthenticated(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(About)
);
