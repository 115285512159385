import { database, functions } from './firebase';

export const fetchFeed = () => {
  return database
    .ref('feed')
    .once('value')
    .then(snapshot => {
      return snapshot.val();
    });
};

export const fetchPost = (language, path) => {
  const name = path.split('/').slice(-1)[0];
  return database
    .ref(`posts/${language}/${name}`)
    .once('value')
    .then(snapshot => snapshot.val());
};

function sortF(a, b) {
  var A = /(\d*)(\D*)/g.exec(a);
  var B = /(\d*)(\D*)/g.exec(b);
  var aa = parseInt(A[1]);
  var bb = parseInt(B[1]);
  if (aa > bb) return 1;
  if (aa < bb) return -1;
  if (A[2] > B[2]) return 1;
  if (A[2] < B[2]) return -1;
  return 0;
}

export const fetchPhotos = album => {
  return database
    .ref(`photos/${album}`)
    .once('value')
    .then(snapshot => {
      return Object.keys(snapshot.val())
        .sort(sortF)
        .map(function(key) {
          return snapshot.val()[key];
        });
    });
};

export const fetchOurLocation = () => {
  var cloudF = functions.httpsCallable('getOurLocation');
  return cloudF();
};
