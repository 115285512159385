import { combineReducers } from 'redux';
import reducerRegistry from 'lib/reducerRegistry';

const visible = (state = false, action) => {
  switch (action.type) {
  case 'MODAL_SHOW':
    return true;
  case 'MODAL_HIDE':
    return false;
  default:
    return state;
  }
};

const text = (state = '', action) => {
  switch (action.type) {
  case 'MODAL_SHOW':
    return action.text;
  case 'MODAL_HIDE':
    return '';
  default:
    return state;
  }
};

const yesno = (state = false, action) => {
  switch (action.type) {
  case 'MODAL_SHOW':
    return action.yesno;
  case 'MODAL_HIDE':
    return false;
  default:
    return state;
  }
};

const handler = (state = null, action) => {
  switch (action.type) {
  case 'MODAL_SHOW':
    return action.handler;
  case 'MODAL_HIDE':
    return null;
  default:
    return state;
  }
};

const modal = combineReducers({
  visible,
  text,
  yesno,
  handler
});

reducerRegistry.register('modal', modal);

export const getModalVisible = state => state.modal.visible;
export const getModalText = state => state.modal.text;
export const getModalYesNo = state => state.modal.yesno;
export const getModalHandler = state => state.modal.handler;
