import './style.scss';
import React, { Component } from 'react';

class JumbotronFeed extends Component {
  constructor() {
    super();
    this.state = { index: -1 };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.items.length > 0 &&
      (prevProps.items.length === 0 || prevProps.items[0].title !== this.props.items[0].title)
    ) {
      this.chooseStart();
    }
  }

  UNSAFE_componentWillMount() {
    this.chooseStart();
  }

  chooseStart() {
    const { items } = this.props;
    if (items && items.length) this.setState({ index: Math.floor(Math.random() * items.length) });
  }
  // <div className="loaded" style={{ backgroundImage: `url(${item.cover})` }} />
  render() {
    const { items } = this.props;
    if (this.state.index === -1) return <div className="jumbotron-wrapper" />;
    return (
      <div className="jumbotron-wrapper">
        <div
          id="carouselJumbo"
          className="carousel slide"
          data-interval="3000"
          data-pause="false"
          data-ride="carousel"
          data-wrap="true"
        >
          <div className="carousel-inner">
            {items.map((item, index) => (
              <div key={index} className={index === this.state.index ? 'carousel-item active' : 'carousel-item'}>
                <div className="loaded" style={{ backgroundImage: `url(${item.cover})` }} />
                <div className="carousel-caption d-none d-md-block">
                  <div className="title">{item.title}</div>
                </div>
              </div>
            ))}
            <a className="carousel-control-prev" href="#carouselJumbo" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselJumbo" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default JumbotronFeed;
