import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getIsAuthenticated, getFeed, getIsFeedFetching, getErrorMessageFeed } from 'reducers';
import { connect } from 'react-redux';
import * as actions from 'actions';
import FetchError from 'components/FetchError/FetchError';
import FeedList from 'components/FeedList/FeedList';
import Jumbotron from 'components/Jumbotron/Jumbotron';
import Spinner from 'components/Spinner/Spinner';
import { sortByKey } from 'lib/basic';

class Feed extends Component {
  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchData();
    }
  }

  fetchData() {
    const { feed, fetchFeed } = this.props;
    if (feed === undefined || feed.length === 0) fetchFeed();
  }

  render() {
    const {
      match: {
        params: { language }
      },
      feed,
      feedIsFetching,
      feedErrorMessage
    } = this.props;
    console.log(feed);
    if (feedErrorMessage) {
      return <FetchError message={feedErrorMessage} onRetry={() => this.fetchData()} />;
    }
    if (feedIsFetching || feed === undefined) {
      return <Spinner />;
    }
    const items = Object.keys(feed)
      .filter(name => feed[name].link && feed[name].link[`post-${language}`])
      .map(name => ({
        id: name,
        title: feed[name].title[language],
        cover: feed[name].cover,
        link: `blog/${feed[name].path}/${name}`,
        date: feed[name].date
      }))
      .sort(sortByKey('date'))
      .reverse();
    return (
      <div>
        <Jumbotron items={items.map(item => ({ ...item, title: '' }))} />
        <div>
          <FeedList items={items} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feed: getFeed(state),
    feedIsFetching: getIsFeedFetching(state),
    feedErrorMessage: getErrorMessageFeed(state),
    authenticated: getIsAuthenticated(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(Feed)
);
