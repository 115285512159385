import './style.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  getIsAuthenticated,
  getFeed,
  getIsFeedFetching,
  getErrorMessageFeed,
  getPhotos,
  getIsFetchingPhotos,
  getErrorMessagePhotos
} from 'reducers';
import { connect } from 'react-redux';
import * as actions from 'actions';
import getText from 'languages';
import FetchError from 'components/FetchError/FetchError';
import Spinner from 'components/Spinner/Spinner';
import Tiles from 'components/Tiles/Tiles';
import Jumbotron from 'components/Jumbotron/Jumbotron';
import { resizeImage } from 'lib/image';

class PhotoVideo extends Component {
  constructor() {
    super();
    this.state = { visible: false, index: 0, prevTransition: false, nyxtTransition: false, fadeAlbumViewer: true };
    this.onLeft = this.onLeft.bind(this);
    this.onRight = this.onRight.bind(this);
    this.onClose = this.onClose.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.prepareJumboData = this.prepareJumboData.bind(this);
    this.onThumbClick = this.onThumbClick.bind(this);
  }

  onThumbClick = index => {
    this.setState({ index });
  };

  handleTouchStart = event => {
    if (event.touches.length === 1) {
      this.setState({ touch: { x: event.touches[0].clientX, y: event.touches[0].clientY } });
    }
    if (event.cancelable) event.preventDefault();
  };

  handleTouchMove = event => {
    if (event.cancelable) event.preventDefault();
  };

  handleTouchEnd = event => {
    if (event.cancelable) event.preventDefault();
    // this.setState({ touch: { x: event.touches[0].clientX, y: event.touches[0].clientY } });
    const deltaX = event.changedTouches[0].clientX - this.state.touch.x;
    if (deltaX < -50) {
      this.onRight();
    } else if (deltaX > 50) {
      this.onLeft();
    }
  };

  handleMouseDown = event => {};

  handleMouseMove = event => {};

  handleMouseUp = () => {};

  handleMouseLeave = () => {};

  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchData();
    }
  }

  keyDown(event) {
    if (event.key === 'ArrowRight') this.onRight();
    if (event.key === 'ArrowLeft') this.onLeft();
    if (event.key === 'Escape') this.onClose();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false);
  }

  fetchData() {
    const {
      match: {
        params: { language }
      },
      feed,
      fetchFeed
    } = this.props;
    if (feed === undefined || feed.length === 0) fetchFeed(language);
    const {
      fetchPhotos,
      nextPageToken,
      match: {
        params: { album }
      }
    } = this.props;
    if (album) {
      this.setState({
        visible: true,
        index: 0,
        prevTransition: false,
        nextTransition: false,
        fadeAlbumViewer: false
      });
      fetchPhotos(album, nextPageToken);
    }
  }

  getName(album) {
    const {
      match: {
        params: { language }
      }
    } = this.props;
    if (album.title[language]) return album.title[language];
    if (album.title['en']) return album.title['en'];
    return '';
  }

  getLinkTo(album) {
    const {
      match: { url }
    } = this.props;
    return url + '/' + album.name;
  }

  convertAlbumToTile(album) {
    return { id: album.name, title: this.getName(album), linkTo: this.getLinkTo(album), img: album.cover };
  }

  prepareData() {
    const { feed } = this.props;
    return Object.keys(feed)
      .filter(name => feed[name].link && feed[name].link.album)
      .map(name => this.convertAlbumToTile({ name, ...feed[name] }));
  }

  prepareAdmin() {
    const {
      match: {
        params: { language }
      }
    } = this.props;
    return {
      id: 'addAlbum',
      title: getText(language, '/albums/insert'),
      linkTo: this.props.match.url + '/insert',
      img: '/img/add_photo.png',
      admin: true
    };
  }

  onRight() {
    const { photos } = this.props;
    const { index } = this.state;
    this.setState({ ...this.state, nextTransition: true });
    setTimeout(() => {
      this.setState({ index: (index + 1) % photos.length, nextTransition: false });
    }, 300);
  }

  onLeft() {
    const { photos } = this.props;
    const { index } = this.state;
    this.setState({ ...this.state, prevTransition: true });
    setTimeout(() => {
      this.setState({ index: (index + photos.length - 1) % photos.length, prevTransition: false });
    }, 300);
  }

  onClose() {
    this.setState({ fadeAlbumViewer: true });
    setTimeout(() => {
      this.props.history.goBack();
      this.setState({ visible: false });
    }, 300);
  }

  renderAlbumViewer() {
    const { prevTransition, nextTransition, index, visible, fadeAlbumViewer } = this.state;
    const {
      photos,
      feed,
      match: {
        params: { language, album }
      }
    } = this.props;
    if (!visible) {
      return null;
    }
    if (!photos || photos.length === 0) {
      return (
        <div className={'album-viewer' + (fadeAlbumViewer ? ' fadeAlbumViewer' : '')}>
          <Spinner className="spinner" />
        </div>
      );
    }
    if (feed[album].link.video && photos[0].video === undefined) {
      photos.unshift({
        url: '/img/youtube.svg',
        alt: 'video',
        title: { [language]: '' },
        video: feed[album].link.video
      });
    }
    const photosBefore = photos.slice(0, index);
    const photosAfter = photos.slice(index + 1);
    const thumbnails = (
      <div className="d-flex flex-row thumb">
        <div className="d-flex flex-row justify-content-end before">
          {photosBefore.map((photo, i) => (
            <div className="notactive" key={photo.url}>
              <img src={resizeImage(photo.url, 200)} alt={photo.title[language]} onClick={() => this.onThumbClick(i)} />
            </div>
          ))}
        </div>
        <div className="middle">
          <img src={resizeImage(photos[index].url, 200)} alt={photos[index].title[language]} />
        </div>
        <div className="d-flex flex-row justify-content-start after">
          {photosAfter.map((photo, i) => (
            <div className="notactive" key={photo.url}>
              <img
                src={resizeImage(photo.url, 200)}
                alt={photo.title[language]}
                onClick={() => this.onThumbClick(index + 1 + i)}
              />
            </div>
          ))}
        </div>
      </div>
    );
    const nextIndex = (index + 1) % photos.length;
    const prevIndex = (index + photos.length - 1) % photos.length;
    return (
      <div className={'d-flex flex-column album-viewer' + (fadeAlbumViewer ? ' fadeAlbumViewer' : '')}>
        <img alt="close" src="/img/close-128.png" className="close-button" onClick={this.onClose} />
        <div className="view">
          <div className="clear" />
          <img alt="left" src="/img/left-128.png" className="left-button" onClick={this.onLeft} />
          <img alt="right" src="/img/right-128.png" className="right-button" onClick={this.onRight} />
          <div className={'next ' + (nextTransition ? 'next-transition' : '')}>
            <img
              className="photo"
              key={photos[nextIndex].url}
              src={photos[nextIndex].url}
              alt={photos[nextIndex].title[language]}
            />
            <div className="text">{photos[nextIndex].title[language]}</div>
          </div>
          <div className={'prev ' + (prevTransition ? 'prev-transition' : '')}>
            <img
              className="photo"
              key={photos[prevIndex].url}
              src={photos[prevIndex].url}
              alt={photos[prevIndex].title[language]}
            />
            <div className="text">{photos[prevIndex].title[language]}</div>
          </div>
          <div
            className={
              'active ' +
              (prevTransition ? 'active-transition-right' : '') +
              (nextTransition ? 'active-transition-left' : '')
            }
            onClick={this.onRight}
          >
            {photos[index].video ? (
              <div className="videoWrapper">
                <iframe
                  src={photos[index].video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title="video"
                  allowFullScreen
                />
              </div>
            ) : (
              <div>
                <img
                  className="photo"
                  onTouchStart={event => this.handleTouchStart(event)}
                  onTouchMove={event => this.handleTouchMove(event)}
                  onTouchEnd={event => this.handleTouchEnd(event)}
                  key={photos[index].url}
                  src={photos[index].url}
                  alt={photos[index].title[language]}
                />
                <div className="text">{photos[index].title[language]}</div>
              </div>
            )}
          </div>
        </div>
        <div className="thumbs">{thumbnails}</div>
      </div>
    );
  }

  prepareJumboData() {
    const { feed } = this.props;
    if (feed === undefined || feed === null) {
      return [];
    }
    return Object.keys(feed)
      .filter(name => feed[name].link && feed[name].link.album)
      .map(name => ({
        id: name,
        // title: feed[name].title[language],
        cover: feed[name].cover,
        link: this.getLinkTo({ name })
      }));
  }

  onScroll(event) {
    console.log(event);
  }

  render() {
    const { feed, feedErrorMessage, authenticated, feedIsFetching } = this.props;
    if (feedErrorMessage) {
      return <FetchError message={feedErrorMessage} onRetry={() => this.fetchData()} />;
    }
    if (feedIsFetching || feed === undefined || feed.length === 0) {
      return <Spinner />;
    }
    const tiles = this.prepareData().concat(authenticated ? this.prepareAdmin() : []);
    if (tiles.length === 0) return <Spinner />;
    return (
      <div onScroll={() => console.log('scroll')}>
        <Jumbotron items={this.prepareJumboData()} />
        <Tiles tiles={tiles} />
        {this.renderAlbumViewer()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feed: getFeed(state),
    feedIsFetching: getIsFeedFetching(state),
    feedErrorMessage: getErrorMessageFeed(state),
    photos: getPhotos(state),
    photosIsFetching: getIsFetchingPhotos(state),
    photosErrorMessage: getErrorMessagePhotos(state),
    authenticated: getIsAuthenticated(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(PhotoVideo)
);
