import {
  getIsPostFetching,
  getIsFeedFetching,
  getIsFetchingPhotos,
  getIsOurLocationFetching,
  getOurLocation
} from 'reducers';
import * as api from 'api';

export const fetchPost = (language, path) => (dispatch, getState) => {
  if (getIsPostFetching(getState())) {
    return Promise.resolve();
  }

  dispatch({
    type: 'FETCH_POST_REQUEST'
  });

  return api.fetchPost(language, path).then(
    response => {
      dispatch({
        type: 'FETCH_POST_SUCCESS',
        response
      });
      return response;
    },
    error => {
      dispatch({
        type: 'FETCH_POST_FAILURE',
        message: error.message || 'Something went wrong.'
      });
    }
  );
};

export const fetchFeed = () => (dispatch, getState) => {
  if (getIsFeedFetching(getState())) {
    return Promise.resolve();
  }

  dispatch({
    type: 'FETCH_FEED_REQUEST'
  });

  return api
    .fetchFeed()
    .then(result => {
      dispatch({
        type: 'FETCH_FEED_SUCCESS',
        response: result
      });
    })
    .catch(error => {
      dispatch({
        type: 'FETCH_FEED_FAILURE',
        message: error.message
      });
    });
};

export const fetchPhotos = album => (dispatch, getState) => {
  if (getIsFetchingPhotos(getState())) {
    return Promise.resolve();
  }
  dispatch({
    type: 'FETCH_PHOTOS_REQUEST'
  });

  return api
    .fetchPhotos(album)
    .then(result => {
      dispatch({ type: 'FETCH_PHOTOS_SUCCESS', response: result });
    })
    .catch(error => {
      dispatch({ type: 'FETCH_PHOTOS_FAILURE', message: error.message });
    });
};

export const fetchOurLocation = () => (dispatch, getState) => {
  if (getIsOurLocationFetching(getState()) || getOurLocation(getState()) === {}) {
    return Promise.resolve();
  }

  dispatch({ type: 'FETCH_OURLOCATION_REQUEST' });

  return api
    .fetchOurLocation()
    .then(result => {
      dispatch({ type: 'FETCH_OURLOCATION_SUCCESS', response: result.data });
    })
    .catch(error => {
      dispatch({ type: 'FETCH_OURLOCATION_FAILURE', message: error.message });
    });
};

export const showModal = (text, yesno = false, handler = null) => dispatch => {
  dispatch({
    type: 'MODAL_SHOW',
    text,
    yesno,
    handler
  });
};

export const hideModal = () => dispatch => {
  dispatch({
    type: 'MODAL_HIDE'
  });
};

export const upsertAlbum = (...params) => () => {
  return api.upsertAlbum(...params);
};

export const upsertSection = (...params) => () => {
  return api.upsertSection(...params);
};
