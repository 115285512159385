import './tile.scss';
import React, { Component } from 'react';
import { getIsAuthenticated } from 'reducers/index';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Textfit } from 'react-textfit';

class Tile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRandAngle() {
    var angle = Math.random() * 10 + 5;
    if (Math.random() < 0.5) angle = -angle;
    return angle;
  }

  onMouseEnter = () => {
    const angle = this.getRandAngle();
    const cos = Math.cos((angle * Math.PI) / 180);
    const sin = Math.sin((angle * Math.PI) / 180);
    this.setState({
      transform: `scale(1.2) rotate(${angle}deg)`,
      boxShadow: `calc(
          var(--shadow-height) * (${cos} * var(--shadow-right) + ${sin} * var(--shadow-left))
        )
        calc(var(--shadow-height) * (${cos} * var(--shadow-left) - ${sin} * var(--shadow-right))) 5px
        5px #00000066`
    });
  };

  onMouseLeave = () => {
    this.setState({
      boxShadow: 'var(--shadow-right) var(--shadow-left) 5px 0px #00000088',
      transform: ''
    });
  };

  render() {
    const { name, linkTo, imgSrc, admin, authenticated } = this.props;
    return (
      <div className="col-sm-6 col-md-4 col-xl-3 justify-content-center">
        <div style={this.state} className="tile" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <Link to={linkTo}>
            <div>
              <img src={imgSrc} alt={name} className="cover" />
            </div>
            <div className="title">
              <Textfit mode="single" forceSingleModeWidth={false}>
                {name}
              </Textfit>
            </div>
          </Link>
          {authenticated && !admin ? (
            <Link to={linkTo + '/update'} className="tile__edit">
              <img src="/img/edit.png" className="tile__edit" alt="edit" />{' '}
            </Link>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: getIsAuthenticated(state)
});

export default connect(
  mapStateToProps,
  null
)(Tile);
