import './style.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Item from './components/Item/Item';

class FeedList extends Component {
  render() {
    const { items } = this.props;
    return (
      <div className="row feedlist">
        {items.map(item => (
          <Item key={item.id} {...item} />
        ))}
      </div>
    );
  }
}

export default withRouter(FeedList);
