import { database } from './firebase';

const upsertPhotos = (language, name, photos) => {
  photos.map((photo, index) => {
    database.ref(`photos/${name}/${index}`).update({ url: photo.url });
    database.ref(`photos/${name}/${index}/title`).update({ [language]: photo.title[language] });
  });
};

export const upsertAlbum = (language, name, title, date, cover, photos) => {
  // update
  database.ref(`feed/${name}/link`).update({ album: true });
  return database.ref(`feed/${name}`).update({ name, date, cover }, error => {
    if (!error)
      return database.ref(`feed/${name}/title`).update({ [language]: title[language] }, error => {
        if (!error) return upsertPhotos(language, name, photos);
      });
  });
};
