import './style.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getOurLocation, getIsOurLocationFetching } from 'reducers';
import { connect } from 'react-redux';
import * as actions from 'actions';
import Spinner from 'components/Spinner/Spinner';
import getText from 'languages';

const Update = (datetime, data, language) => (
  <div key={datetime}>
    <div className="datetime">{datetime}</div>
    {data.text[language]}
    {data.images ? data.images.map(image => <img className="image-inline" alt="" key={image} src={image} />) : null}
  </div>
);

class OurLocation extends Component {
  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchData();
    }
  }

  fetchData() {
    const { ourLocation, ourLocationIsFetching, fetchOurLocation } = this.props;
    if (!ourLocationIsFetching || ourLocation === undefined || ourLocation.length === 0) fetchOurLocation();
  }

  render() {
    const {
      match: {
        params: { language }
      },
      ourLocation,
      ourLocationIsFetching
    } = this.props;
    if (ourLocationIsFetching || ourLocation === null) {
      return <Spinner />;
    }
    console.log(ourLocation);
    const updates = (
      <div>
        {Object.keys(ourLocation)
          .reverse()
          .map(datetime => Update(datetime, ourLocation[datetime], language))}
      </div>
    );
    return (
      <div className="our-location">
        {updates}
        <div className="desc">{getText(language, '/our_location/desc')}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ourLocation: getOurLocation(state),
    ourLocationIsFetching: getIsOurLocationFetching(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(OurLocation)
);
