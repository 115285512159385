import { combineReducers } from 'redux';
import reducerRegistry from 'lib/reducerRegistry';

const user = (state = null, action) => {
  switch (action.type) {
  case 'AUTH_USER_LOGGED_IN':
    return action.result;
  default:
    return state;
  }
};

const auth = combineReducers({
  user
});

reducerRegistry.register('auth', auth);

export const getUser = state => state.auth.user;
export const getIsAuthenticated = state => getUser(state) && getUser(state).credential !== undefined;
