import './header.scss';
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import getText from 'languages';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.renderNavButton = this.renderNavButton.bind(this);
  }

  renderNavButton(language, button) {
    // const { ourLocation } = this.props;
    // if (button.id === '/header/our_location' && (!ourLocation.data || !ourLocation.data[language]))
    //   return <div className="nav-link our_location"> {getText(language, button.id)}</div>;
    return (
      <Link to={'/' + language + '/' + button.link} className="nav-link">
        {getText(language, button.id)}
      </Link>
    );
  }

  renderNavBarButton(language, button) {
    if (button.id === '/header/logo') return <li key="logo" className="nav-item" />;
    return (
      <Fragment key={button.id}>
        <li
          key={`${button.id}hide`}
          className="nav-item hideable-navbar"
          data-toggle="collapse"
          data-target="#navbarText"
        >
          {this.renderNavButton(language, button)}
        </li>
        <li key={`${button.id}show`} className="nav-item nonhideable-navbar">
          {this.renderNavButton(language, button)}
        </li>
      </Fragment>
    );
  }

  renderLanguageButtons(language) {
    return (
      <Fragment>
        <Link to={this.props.location.pathname.replace(/\/[^/]*/, '/sk')} className="nav-link-flag">
          <img alt="slovensky" src={language === 'sk' ? '/img/flag-slovak.png' : '/img/flag-disabled-slovak.png'} />
        </Link>
        <Link to={this.props.location.pathname.replace(/\/[^/]*/, '/en')} className="nav-link-flag">
          <img alt="english" src={language === 'en' ? '/img/flag-english.png' : '/img/flag-disabled-english.png'} />
        </Link>{' '}
      </Fragment>
    );
  }

  render() {
    const {
      match: {
        params: { language }
      }
    } = this.props;
    const Buttons = [
      {
        id: '/header/about',
        link: 'about'
      },
      {
        id: '/header/blog',
        link: 'blog'
      },
      {
        id: '/header/destinations',
        link: 'destinations'
      },
      {
        id: '/header/logo',
        link: 'logo'
      },
      {
        id: '/header/photo_video',
        link: 'photo_video'
      },
      {
        id: '/header/our_location',
        link: 'our-location'
      }
    ];
    return (
      <header>
        <img id="logo" alt="logo" src="/img/slovakia.svg" className="background" />
        <h1>
          <span className="c1">H</span>
          <span className="c2">a</span>
          <span className="c3">p</span>
          <span className="c4">p</span>
          <span className="c5">y</span>
          <span className="c7">o</span>
          <span className="c8">n</span>
          <span className="c10">t</span>
          <span className="c11">h</span>
          <span className="c12">e</span>
          <span className="c14">r</span>
          <span className="c15">o</span>
          <span className="c16">a</span>
          <span className="c17">d</span>
        </h1>
        <nav className="navbar navbar-expand-lg navbar-default fixed-top background">
          <Link to={`/${language}/blog`} className="nav-link nav-item d-block d-lg-none">
            Happy On The Road
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div id="navbarText" className="collapse navbar-collapse">
            <ul id="nav-list" className="nav navbar-nav w-100">
              {Buttons.map(button => this.renderNavBarButton(language, button))}
              <li
                key="language-hide"
                className="nav-item hideable-navbar"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                {this.renderLanguageButtons(language)}
              </li>
              <li key="language-nonhide" className="nav-item nonhideable-navbar">
                {this.renderLanguageButtons(language)}
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Nav);
