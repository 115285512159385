import './style.scss';
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  getPost,
  getFeed,
  getIsFeedFetching,
  getErrorMessageFeed,
  getIsAuthenticated,
  getIsPostFetching,
  getErrorMessagePost
} from 'reducers';
import { connect } from 'react-redux';
import * as actions from 'actions';
import FetchError from 'components/FetchError/FetchError';
import Spinner from 'components/Spinner/Spinner';
import Jumbotron from 'components/Jumbotron/Jumbotron';
import Tile from 'components/Tiles/components/Tile/Tile';

class Post extends Component {
  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      match: {
        params: { language, path }
      },
      fetchPost,
      feed,
      fetchFeed
    } = this.props;
    fetchPost(language, path);
    if (feed === undefined || feed.length === 0) fetchFeed();
  }

  render() {
    const {
      feed,
      post,
      isFetching,
      errorMessage,
      authenticated,
      match: {
        url,
        params: { path, language }
      }
    } = this.props;
    if (isFetching !== false || !post || !feed || feed.length === 0) {
      return <Spinner />;
    }
    if (errorMessage) {
      return <FetchError message={errorMessage} onRetry={() => this.fetchData()} />;
    }
    const name = path.split('/').slice(-1)[0];
    return (
      <div>
        <title>{feed[name].title[language]}</title>
        <meta name="description" content={post.text.toString()} />
        <Jumbotron items={[{ id: name, cover: feed[name].cover }]} />
        {authenticated ? (
          <Link to={url + '/update'}>
            <img src="/img/edit.png" className="tile__edit" alt="edit" />{' '}
          </Link>
        ) : null}
        <div className="container-fluid blog-text">
          <div className="content" dangerouslySetInnerHTML={{ __html: post.text.toString() }} />
        </div>
        {feed[name].link.album ? (
          <div className="row justify-content-md-center">
            <Tile name="Album" linkTo={`/${language}/photo_video/${name}`} imgSrc={feed[name].cover} />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feed: getFeed(state),
    feedIsFetching: getIsFeedFetching(state),
    feedErrorMessage: getErrorMessageFeed(state),
    post: getPost(state),
    isFetching: getIsPostFetching(state),
    errorMessage: getErrorMessagePost(state),
    authenticated: getIsAuthenticated(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(Post)
);
