import React, { Component } from 'react';
import { getUser } from 'reducers';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

class Login extends Component {
  componentDidMount() {
    const { userLogIn } = this.props;
    userLogIn();
  }

  render() {
    if (this.props.user && this.props.user.credential) {
      this.props.history.goBack();
    }
    return <div>Loggin In</div>;
  }
}

const mapStateToProps = state => ({
  user: getUser(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(Login)
);
