import './tiles.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Tile from './components/Tile/Tile';

class Tiles extends Component {
  render() {
    const { tiles } = this.props;
    return (
      <div className="container-fluid">
        <div id="main" className="row">
          {tiles.map(tile => {
            return (
              <Tile
                key={tile.id}
                name={tile.title}
                linkTo={tile.linkTo}
                imgSrc={tile.img ? (tile.admin ? tile.img : tile.img.replace('/s1600/', '/w240-h220-c/')) : ''}
                admin={tile.admin}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(Tiles);
