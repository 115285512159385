import {} from '../reducers';
import * as api from '../../api';

export const userLogIn = () => dispatch => {
  return api
    .userLogIn()
    .then(function(result) {
      // api.getCredentials(result.credential);
      dispatch({
        type: 'AUTH_USER_LOGGED_IN',
        result
      });
    })
    .catch(function(error) {});
};

export const upsertPost = (...params) => () => {
  return api.upsertPost(...params);
};
