export const capitalize = text => {
  let lastWhite = true;
  let capitalized = '';
  for (let i = 0; i < text.length; ++i) {
    let c = text[i];
    if (c === '\t') c = ' ';
    if (c === '\n') c = ' ';
    if (c === '_') c = ' ';
    if (lastWhite) {
      lastWhite = false;
      c = c.toUpperCase();
    } else {
      c = c.toLowerCase();
    }
    capitalized += c;
    if (c === ' ') lastWhite = true;
  }
  return capitalized;
};

export const ToId = text => {
  let id = '';
  for (let i = 0; i < text.length; ++i) {
    let c = text[i];
    if (c === '\t') c = '_';
    if (c === '\n') c = '_';
    if (c === ' ') c = '_';
    id += c.toLowerCase();
  }
  return id;
};

export const randItem = items => {
  return items[Math.floor(Math.random() * items.length)];
};

const sortString = (a, b) => {
  const x = a.toLowerCase();
  const y = b.toLowerCase();
  return x > y ? 1 : x < y ? -1 : 0;
};

export const sortBySecondTitle = (a, b) => {
  const x = a.title.split(' ').reverse();
  const y = b.title.split(' ').reverse();
  if (x[0] === y[0]) return sortString(x[1], y[1]);
  return sortString(x[0], y[0]);
};

export const sortByTitle = (a, b) => sortString(a.title, b.title);

export const sortByKey = key => (a, b) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0);

export const findRegexGroups = (re, string) => {
  let m;
  let X = [];
  while ((m = re.exec(string))) X.push(m[1]);
  return X;
};
