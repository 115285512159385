import { combineReducers } from 'redux';
import reducerRegistry from 'lib/reducerRegistry';

function createBasicReducer(name, defaultData) {
  const data = (state = defaultData, action) => {
    switch (action.type) {
    case `FETCH_${name.toUpperCase()}_SUCCESS`:
      return action.response;
    default:
      return state;
    }
  };

  const beforeFirstInit = (state = true, action) => {
    switch (action.type) {
    case `FETCH_${name.toUpperCase()}_REQUEST`:
    case `FETCH_${name.toUpperCase()}_SUCCESS`:
    case `FETCH_${name.toUpperCase()}_FAILURE`:
      return false;
    default:
      return state;
    }
  };

  const isFetching = (state = false, action) => {
    switch (action.type) {
    case `FETCH_${name.toUpperCase()}_REQUEST`:
      return true;
    case `FETCH_${name.toUpperCase()}_SUCCESS`:
    case `FETCH_${name.toUpperCase()}_FAILURE`:
      return false;
    default:
      return state;
    }
  };

  const errorMessage = (state = null, action) => {
    switch (action.type) {
    case `FETCH_${name.toUpperCase()}_FAILURE`:
      return action.message;
    default:
      return state;
    }
  };

  const handler = combineReducers({
    data,
    isFetching,
    beforeFirstInit,
    errorMessage
  });

  reducerRegistry.register(name, handler);
}

export default createBasicReducer;

// export const getPost = state => state.post.post;
// export const getIsFetching = state => state.post.isFetching;
// export const getErrorMessage = state => state.post.errorMessage;
