import "babel-polyfill";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import './common.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';

const store = configureStore();

const render = () => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
};
// Store.subscribe(render);
render();
// registerServiceWorker();
