import { database } from './firebase';

export const upsertSection = (name, path, title) => {
  return database.ref(`feed/${name}`).update({ path: path ? path : '', title }, error => {
    if (error) {
      console.log(error);
    }
  });
};

export const upsertPathCover = (lang, id, cover) => {
  return database
    .ref('path')
    .orderByChild(`post/${lang}`)
    .equalTo(`/language/${lang}/post/${id}`)
    .on('child_added', snapshot => {
      return database.ref(`path/${snapshot.key}`).update({ img: cover });
    });
};
