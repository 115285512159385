import { createStore, applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducerRegistry from './lib/reducerRegistry.js';
import { combineReducers } from 'redux';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {};

const configureStore = () => {
  const middlewares = [thunk];

  // middlewares.push(logger);

  const combine = reducers => {
    const reducerNames = Object.keys(reducers);
    Object.keys(initialState).forEach(item => {
      if (reducerNames.indexOf(item) === -1) {
        reducers[item] = (state = null) => state;
      }
    });
    return combineReducers(reducers);
  };

  let store = createStore(
    combine(reducerRegistry.getReducers()),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  reducerRegistry.setChangeListener(reducers => {
    store.replaceReducer(combine(reducers));
  });

  return store;
};

export default configureStore;
