const English = {
  '/albums/insert': 'Insert',
  '/form/button/reset': 'Reset',
  '/form/button/submit': 'Submit',
  '/form/reset': 'Do you want to reset the form?',
  '/header/about': 'About Us',
  '/header/blog': 'Blog',
  '/header/destinations': 'Destinations',
  '/header/our_location': 'Our Location',
  '/header/photo_video': 'Photo & Video',
  '/header/search': 'Search',
  '/modal/no': 'No',
  '/modal/ok': 'Ok',
  '/modal/yes': 'Yes',
  '/post/insert_post': 'Insert post',
  '/post/insert_category': 'Insert category',
  '/upsert_album/name': 'Url name',
  '/upsert_album/photo_title': 'Title',
  '/upsert_post/name': 'URL Name',
  '/upsert_post/text': 'Text',
  '/upsert_post/title': 'Title',
  '/upsert_section/id': 'Id (debug)',
  '/upsert_section/imgSrc': 'Image source url',
  '/upsert_section/name': 'URL Name',
  '/upsert_section/title': 'Section title',
  '/loadmore': 'Load more',
  '/about/us': 'Picture of us in Nepal at Poon hill. In the background is Dhaulagiri, 7th highest peak in the world.',
  '/our_location/desc':
    'Some of these texts are written in advance, in case that we know that we will not have an internet connection ;-)'
};

export default English;
